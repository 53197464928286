
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreatePostModule } from '@/store';
import { ValidationRules } from '@/components/modals/validationRules';

// types

const createPostModule = getModule(CreatePostModule);

@Component({
  components: {}
})
export default class CreatePostSchedule extends LoggedInComponentBase {
  public rules: ValidationRules = new ValidationRules();
  public isDateModalVisible = false;
  public isTimeModalVisible = false;

  public get isScheduledImmediately(): boolean {
    return createPostModule.isScheduledImmediately;
  }
  public set isScheduledImmediately(flag: boolean) {
    createPostModule.setIsScheduledImmediately(flag);
  }

  public get scheduledDate(): string | null {
    return createPostModule.scheduledDate;
  }
  public set scheduledDate(value: string | null) {
    createPostModule.setScheduledDate(value);
  }

  public get scheduledTime(): string | null {
    return createPostModule.scheduledTime;
  }

  public set scheduledTime(value: string | null) {
    createPostModule.setScheduledTime(value);
  }
}
