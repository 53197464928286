
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop, PropSync } from 'vue-property-decorator';

// types
import { DataTableHeader } from 'vuetify';
import { DataTableItem } from '@/models/posts/postModal';

@Component
export default class CreatePostStoreChannelsTable extends LoggedInComponentBase {
  @Prop({ default: [], required: true }) headers!: DataTableHeader[];
  @PropSync('items', { default: [], required: true })
  syncedItems!: DataTableItem[];
}
