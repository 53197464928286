
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

interface Steps {
  [key: string]: string[];
}

const steps: Steps = {
  post: [
    'Create post',
    'Choose preset',
    'Edit preset',
    'Post information',
    'Schedule'
  ],
  asset: [
    'Asset and styling',
    'Asset and styling',
    'Asset information',
    'Asset information'
  ]
};

@Component({
  components: {}
})
export default class CreateNavigation extends LoggedInComponentBase {
  @Prop() isPost!: boolean;
  @Prop() currentStep!: number;
  @Prop() isNextDisabled!: boolean;
  @Prop() nextBtnText!: string;
  @Prop({ default: false }) readonly isSubmitting!: boolean;

  public get isBackVisible(): boolean {
    return this.currentStep > 1;
  }

  public get isCancelVisible(): boolean {
    return this.currentStep === 1;
  }

  public get title(): string {
    return steps[this.isPost ? 'post' : 'asset'][this.currentStep - 1];
  }
}
