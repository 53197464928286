import { CampaignCategoryParams } from '@/models/campaigns/campaignCategoryParams';

export class CampaignCategory {
  id?: guid = '';
  title?: string = '';
  description?: string = '';
  subCategories?: CampaignCategory[] = [];

  constructor(params?: CampaignCategoryParams) {
    Object.assign(this, params);
  }
}
