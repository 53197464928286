
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreateModule } from '@/store';
import { find } from 'lodash';

// types
import { PresetLabel, PresetListItem } from '@/models/presets/presetListItem';

const createModule = getModule(CreateModule);

@Component({
  components: {}
})
export default class CreatePresetLabels extends LoggedInComponentBase {
  public checkMaxLength(
    currentVal: string,
    maxLength: number | undefined
  ): boolean | string {
    if (!maxLength) return true;
    return currentVal.length <= maxLength || `Max ${maxLength} characters`;
  }

  public get selectedPreset(): PresetListItem {
    return createModule.selectedPreset;
  }

  public get hasLabels() {
    return this.selectedPreset.presetLabels?.length;
  }

  public getHint(label: PresetLabel): string {
    if (!label.maxLength) return '';
    return `${label.maxLength} character maximum`;
  }

  public get presetLabels(): PresetLabel[] {
    return this.selectedPreset.presetLabels || [];
  }

  public onLabelInput(labelId: string, $event: InputEvent) {
    const value = ($event.target as HTMLInputElement).value;
    createModule.setPresetLabelCurrentText({ value, labelId });
  }

  // on focus if value = default text clear the field
  public onLabelFocus(labelId: string, $event: FocusEvent) {
    const presetLabel = this.getPresetLabelByLabelId(labelId);
    const value = ($event.target as HTMLInputElement).value;
    if (presetLabel.defaultText === value) {
      createModule.setPresetLabelCurrentText({
        value: '',
        labelId
      });
    }
  }

  public resetLabels() {
    this.presetLabels.forEach(presetLabel => {
      createModule.setPresetLabelCurrentText({
        value: presetLabel.defaultText,
        labelId: presetLabel.labelId
      });
    });
    this.updateSvgLabels();
  }

  public updateSvgLabels() {
    this.$root.$emit('update-svg-labels');
  }

  public isDefaultValue(labelId: string) {
    const presetLabel = this.getPresetLabelByLabelId(labelId);
    return presetLabel?.defaultText === presetLabel?.currentText;
  }
  private getPresetLabelByLabelId(labelId: string): PresetLabel {
    return find(
      this.selectedPreset.presetLabels,
      label => label.labelId === labelId
    )!;
  }
}
