
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { some } from 'lodash';
import {
  AssetSubmitFeedbackMap,
  PostSubmitFeedbackMap,
  CreateSubmitStatus,
} from '@/models/createSubmitTypes';
import { SocialChannel } from '@/models/posts/socialChannels';

@Component({
  components: {}
})
export default class CreateStepperHeader extends LoggedInComponentBase {
  @Prop() isPost!: boolean;
  @Prop() isVisible!: boolean;
  @Prop() assetSubmitFeedback!: AssetSubmitFeedbackMap;
  @Prop() postSubmitFeedback!: PostSubmitFeedbackMap;

  public CreateSubmitStatus = CreateSubmitStatus;
  public SocialChannel = SocialChannel;

  public get isAssetFeedbackVisible(): boolean {
    return !!Object.keys(this.assetSubmitFeedback).length;
  }

  public get isPostFeedbackVisible(): boolean {
    return !!Object.keys(this.postSubmitFeedback).length;
  }

  public get hasError(): boolean {
    const hasAssetError = some(this.assetSubmitFeedback, item => {
      return item.status === CreateSubmitStatus.FAIL;
    });
    const hasPostError = some(this.postSubmitFeedback, item => {
      return item.status === CreateSubmitStatus.FAIL;
    });
    return hasAssetError || hasPostError;
  }
}
