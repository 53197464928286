var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._b({staticClass:"store-channels",attrs:{"headers":_vm.headers,"items":_vm.syncedItems,"items-per-page":-1,"hide-default-footer":true,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:`header.facebook`,fn:function({ header }){return [_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(`mdi-${header.value}`)+" ")])]}},{key:`header.instagram`,fn:function({ header }){return [_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(`mdi-${header.value}`)+" ")])]}},{key:`item.facebook`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"color":"success","disabled":item.facebook.disabled || _vm.$attrs.readonly},on:{"input":value =>
          _vm.$emit('on-channel-toggled', {
            tableItem: item,
            channel: 'Facebook',
            value
          })},model:{value:(item.facebook.value),callback:function ($$v) {_vm.$set(item.facebook, "value", $$v)},expression:"item.facebook.value"}})]}},{key:`item.instagram`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"color":"success","disabled":item.instagram.disabled || _vm.$attrs.readonly},on:{"input":value =>
          _vm.$emit('on-channel-toggled', {
            tableItem: item,
            channel: 'Instagram',
            value
          })},model:{value:(item.instagram.value),callback:function ($$v) {_vm.$set(item.instagram, "value", $$v)},expression:"item.instagram.value"}})]}}],null,true)},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }