
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreateAssetModule, CampaignModule } from '@/store';
import { union, uniq } from 'lodash';

// types
import { SocialChannel, SocialChannels } from '@/models/posts/socialChannels';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { Region } from '@/models/stores/regions';
import { MsoAssetStoreSummary } from '@/models/assets/assetListItem';
import { DataTableHeader } from 'vuetify';
import { ALLSTORES } from '@/constants';

const createAssetModule = getModule(CreateAssetModule);
const campaignModule = getModule(CampaignModule);

@Component({
  components: {}
})
export default class CreateAssetInfo extends LoggedInComponentBase {
  //
  // channels
  //
  public socialChannels = SocialChannels;

  public get assetChannels(): SocialChannel[] {
    return createAssetModule.channels;
  }
  public set assetChannels(value: SocialChannel[]) {
    createAssetModule.setChannels(value);
  }

  //
  // categories
  //
  public get categories(): CampaignCategory[] {
    // Add the static 'None' filter
    const allFilter = new CampaignCategory({
      id: 'none',
      title: 'None'
    });

    return [allFilter, ...campaignModule.campaignCategories];
  }

  public get selectedCategory(): CampaignCategory | null {
    return createAssetModule.selectedCategory;
  }
  public set selectedCategory(category: CampaignCategory | null) {
    if (category?.id === 'resetting') category = null;
    createAssetModule.setSelectedCategory(category);
  }

  public onCategorySelect(returnedObj: CampaignCategory) {
    // resets select
    if (returnedObj.id === 'none')
      //@ts-ignore
      this.$refs['categorySelect'].internalValue = {
        id: 'resetting'
      };
  }

  //
  // mso selected stores
  //
  public tableHeaders: DataTableHeader[] = [
    {
      text: 'Stores',
      value: 'name',
    },
    {
      text: '',
      value: 'data-table-select'
    }
  ];

  public get isViewingMultipleStores(): boolean {
    return !this.userModule.isViewingSingleStore;
  }

  public toggleAllStoresInRegion(region: Region, value: Region | null) {
    const isDeselect: boolean = value === null;

    // remove stores to selectedStores[] by region
    if (isDeselect) {
      this.msoSelectedStores = this.msoSelectedStores.filter(
        store => store.region !== region
      );
      return;
    }

    // add stores to selectedStores[] by region
    const allStoreOptionsInRegion = this.tableItems.filter(
      row => row.region === region
    );

    this.msoSelectedStores = union(
      this.msoSelectedStores,
      allStoreOptionsInRegion
    );
  }

  public get msoSelectedStores(): MsoAssetStoreSummary[] {
    return createAssetModule.msoSelectedStores;
  }

  public set msoSelectedStores(stores: MsoAssetStoreSummary[]) {
    createAssetModule.setMsoSelectedStores(stores);
  }

  public get regions(): Region[] {
    return uniq(this.tableItems.map(store => store.region));
  }

  public get tableItems(): MsoAssetStoreSummary[] {
    return this.storesModule.userStores
      .filter(store => store.title !== ALLSTORES)
      .map(store => ({
        storeId: store.id,
        name: store.title,
        region: store.state
      }));
  }

  public showSelectAllForRegion(region: Region): boolean {
    return Number(this.storesModule.regionalStoreCount.get(region)) > 1;
  }

  public get showSelectAll(): boolean {
    return this.tableItems.some(store =>
      this.showSelectAllForRegion(store.region)
    );
  }
  async mounted() {
    // user may have refreshed the page > populate user stores
    const noStores = !this.storesModule.userStores.filter(
      (store) => store.title !== ALLSTORES
    ).length;
    if (noStores) {
      await this.storesModule.getUserStores();
    }
  }
}
