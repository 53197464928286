
import { Component, PropSync, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Region } from '@/models/stores/regions';
import { AssetListItem } from '@/models/assets/assetListItem';
import type { AssetsServiceClient } from '@/api/clients/assetsServiceClient';

@Component
export default class AssetShareModal extends LoggedInComponentBase {
  @Prop({ default: null, required: true }) asset!: AssetListItem;
  @Prop({ default: null, required: true }) serviceClient!: AssetsServiceClient;
  @PropSync('open', { default: false, required: true })
  dialog!: boolean;

  public selectedRegions: Region[] = [];

  public get regions(): Region[] {
    return this.storesModule.regions.filter(region => region !== Region.ALL);
  }

  public get selectedAnyRegion(): boolean {
    return this.selectedRegions.length > 0;
  }

  public dismissModal(): void {
    this.selectedRegions = [];
    this.dialog = false;
  }

  /**
   * Share this asset with other stores within the same user group.
   * @todo: add success and failure state indicators
   */
  public async shareAsset(): Promise<void> {
    if (this.serviceClient) {
      const getPayload = (region: Region) => {
        const storeId: string = this.userModule.isViewingSingleStore ? this.userModule.currentStore.id : this.asset.msoAssetStores![0].storeId
        return {
          assetId: this.asset.id,
          storeId,
          region
        }
      }
      await Promise.allSettled(
        this.selectedRegions.map(region =>
          this.serviceClient.shareAsset(getPayload(region))
        )
      );
      this.dismissModal();
    }
  }
}
