
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

@Component({
  components: {}
})
export default class CreateVideo extends LoggedInComponentBase {
  public get isVideoVisible(): boolean {
    return !!this.createModule.selectedAsset?.isVideoAsset;
  }

  public get src(): string | undefined {
    return this.createModule.selectedAsset?.blobUrl;
  }

  public get isMobClearBtnVisible() {
    return this.$vuetify.breakpoint.xs && this.createModule.currentStep === 1;
  }

  public reset() {
    this.createModule.reset();
  }
}
