
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { ValidationRules } from '@/components/modals/validationRules';

// types
import { AssetEditingMode } from '@/models/createAssetEditingModes';

@Component({
  components: {}
})
export default class CreateAssetTitle extends LoggedInComponentBase {
  @Prop() isError!: number;
  @Prop() isPost!: boolean;
  @Prop() assetEditingMode!: AssetEditingMode;
  public rules: ValidationRules = new ValidationRules();
  public AssetEditingModes = AssetEditingMode;

  public get isExistingAsset(): boolean {
    return this.createModule.selectedAsset !== null;
  }

  public get assetTitle(): string {
    return this.createAssetModule.assetTitle;
  }

  public set assetTitle(value: string) {
    this.createAssetModule.setAssetTitle(value);
  }

  public get isAssetTitleFieldVisible(): boolean {
    return (
      this.assetEditingMode !== this.AssetEditingModes.Existing &&
      this.assetEditingMode !== this.AssetEditingModes.Video
    );
  }

  @Watch('assetEditingMode')
  private onAssetEditingModeChange(
    newVal: AssetEditingMode,
    oldVal: AssetEditingMode
  ) {
    if (oldVal === AssetEditingMode.Existing) {
      this.assetTitle = '';
    }
  }
}
