
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreateModule } from '@/store';

// types
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignCategoryParams } from '@/models/campaigns/campaignCategoryParams';

const createModule = getModule(CreateModule);

@Component({
  components: {}
})
export default class CreatePresetCategories extends LoggedInComponentBase {
  public get presetCategoryId(): string {
    return createModule.presetCategoryId;
  }

  public set presetCategoryId(id: string) {
    createModule.setPresetCategoryId(id);
  }

  public get categories(): Array<CampaignCategory> {
    const filters: CampaignCategory[] = [];

    // Add the static 'Featured' filter
    const params = new CampaignCategoryParams();
    params.id = 'featured';
    params.title = 'Featured';
    const allFilter = new CampaignCategory(params);
    filters.push(allFilter);

    createModule.presetCategories.forEach(category => {
      filters.push(category);
    });

    return filters;
  }
}
